import * as React from "react";
import * as styles from "./styles.module.scss";
import Footer from "../Footer/Footer";
import NavBar from "./NavBar";
import { scrollToTop } from "utils";

const Layout = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.navWrapper}>
        <NavBar />
      </div>
      <div className={styles.container}>{children}</div>
      <Footer />
      <BackToTopButton />
    </div>
  );
};

function BackToTopButton() {
  const ref = React.useRef(null);

  function onBackToTopClick() {
    scrollToTop();
  }

  function scrollListener() {
    if (!ref.current) return;

    let button = ref.current;
    let scrollTop = document.documentElement.scrollTop;
    let opacity = 0;
    const threshold = 200;

    if (scrollTop >= threshold) {
      opacity += opacity + (scrollTop - threshold) / 100;
    }
    if (opacity >= 0) {
      button.style.opacity = opacity;
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", scrollListener);

    return () => window.removeEventListener("scroll", scrollListener);
  }, []);

  return (
    <div ref={ref} className={styles.backToTopButton}>
      <button onClick={onBackToTopClick}>
        <span>&#8963;</span>
      </button>
    </div>
  );
}

export default Layout;
