export function classes() {
  return [...arguments].join(" ");
}

export function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export function classnames() {
  let stringArray = [];

  [...arguments].forEach((arg) => {
    switch (typeof arg) {
      case "object":
        let sanitized = Object.entries(arg).map(([key, value]) => {
          switch (typeof value) {
            case "string":
            case "boolean":
              return value ? key : "";
            case "number":
              if (value > 0) {
                return key;
              }
              return null;
            default:
              return null;
          }
        });
        stringArray = [...stringArray, ...sanitized];
        break;
      case "string":
        stringArray = [...stringArray, arg];
        break;
      default:
        break;
    }
  });

  return stringArray.filter((i) => i).join(" ");
}

export function sanitizeBody(token = "", body = "") {
  if (!body.includes(token)) return body;
  return body.replace(token, "");
}

export function windowIsDefined() {
  return typeof window !== "undefined";
}

export function escapeHtml(unsafe) {
  return unsafe.replace(/<\/?[a-z]>/g, "");
}

export async function graphqlRequest(query) {
  const url = process.env.WPGRAPHQL_URL;
  const headers = {
    "Content-Type": "application/json"
  };

  if (process.env.WPGRAPHQL_REFRESH_TOKEN) {
    headers["Authorization"] = `Bearer ${process.env.WPGRAPHQL_REFRESH_TOKEN}`;
  }

  const result = await fetch(url, {
    headers,
    method: "POST",
    body: JSON.stringify({ query })
  });

  return await result.json();
}

export function capitalize(str) {
  if (!str || typeof str !== "string") return str;
  const separated = str.split(" ");
  return separated
    .map?.((word, i) => {
      if (i === 0) {
        return word?.[0]?.toUpperCase() + word?.slice?.(1);
      } else {
        return word;
      }
    })
    ?.join(" ");
}
